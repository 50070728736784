export default function Window ({ children, actions, noPadding, autoDimensions }) {
  return (
    <div className={`relative bg-dark rounded flex flex-col w-full max-h-screen overflow-y-auto ${autoDimensions ? 'h-auto w-auto' : 'h-full md:w-auto md:min-w-96'}`}>
      <div className={noPadding ? '' : 'p-4'}>
        {children}
      </div>
      <div className="h-2 w-full"></div>
      {actions && (
        <div className="p-4 mt-auto bg-darker-gray rounded-bl rounded-br flex items-center">
          {actions}
        </div>
      )}
    </div>
  )
}
