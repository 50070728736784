import { faSwords, faTrophy, faGem, faHatWizard, faHammerCrash, faScrollOld, faBookSparkles, faStore, faBeerFoam, faFireFlame, faHandHoldingMagic, faArrowUpRightFromSquare, faList, faBuildingColumns, faCrystalBall } from '@fortawesome/pro-duotone-svg-icons'
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'
import { setToolsMenu } from 'store'
import NavListItem from './NavListItem'

export default function NavList ({ className, iconsOnly }) {
  const user = useSelector(store => store.main.auth.user)
  const toolsOpen = useSelector(store => store.main.toolsMenu.isOpen)
  const router = useRouter()
  const dispatch = useDispatch()
  const userItems = [
    {
      label: 'Tavern',
      icon: faBeerFoam,
      href: '/tavern',
      active: router.asPath && (router.asPath.startsWith('/tavern') || router.asPath === '/shrines'),
      updates: user && user.hasUnreadNews
    },
    {
      label: 'Crafting',
      icon: faHammerCrash,
      href: '/raids/craft'
    },
    {
      label: 'Dungeon Raids',
      icon: faSwords,
      href: '/raids/available',
      active: router.asPath && router.asPath.startsWith('/raids/available')
    },
    {
      label: 'Inventory',
      icon: faHatWizard,
      href: '/account/adventurers',
      active: router.asPath && (router.asPath.startsWith('/account/adventurers') || router.asPath.startsWith('/account/shrines'))
    },
    {
      label: 'Passive staking',
      icon: faBuildingColumns,
      href: '/account/passive-staking',
      active: router.asPath && (router.asPath.startsWith('/account/passive-staking') || router.asPath.startsWith('/account/partner-staking'))
    }
  ]
  const items = [
    ...!user
      ? [
          {
            label: 'Home',
            icon: faFireFlame,
            href: '/'
          }
        ]
      : [],
    ...[
      {
        label: 'Shop',
        icon: faStore,
        href: '/raids/shop'
      },
      {
        label: 'Hall of Fame',
        icon: faTrophy,
        href: '/leaderboard/tavern-brawls',
        active: router.asPath.startsWith('/leaderboard') || router.asPath === '/account/achievements'
      },
      {
        label: 'Tales of the Tavern',
        icon: faBookSparkles,
        href: '/lore/origins/chapter-1',
        active: router.asPath && router.asPath.startsWith('/lore/')
      }
    ],
    ...!user
      ? [
          {
            label: 'Rarity Table',
            icon: faGem,
            href: '/menu'
          },
          {
            label: 'Adventurer Finder',
            icon: faHatWizard,
            href: '/adventurers',
            classes: user ? 'mt-auto' : ''
          },
          {
            label: 'Shrines Finder',
            icon: faCrystalBall,
            href: '/shrines-finder'
          },
          {
            label: 'White Scroll',
            icon: faScrollOld,
            href: 'https://whitescroll.tavernsquad.io',
            target: '_blank',
            iconAfter: faArrowUpRightFromSquare
          }
        ]
      : [
          {
            label: 'Tools',
            icon: faHandHoldingMagic,
            iconAfter: faArrowRight,
            href: '',
            onClick: e => {
              e.preventDefault()
              dispatch(setToolsMenu(true))
            }
          }
        ]
  ]

  const toolsItems = [
    {
      label: 'Tools',
      icon: faHandHoldingMagic,
      iconAfter: faArrowLeft,
      href: '',
      onClick: e => {
        e.preventDefault()
        dispatch(setToolsMenu(false))
      }
    },
    {
      label: 'Transactions',
      icon: faList,
      href: '/account/transactions'
    },
    {
      label: 'Rarity Table',
      icon: faGem,
      href: '/menu'
    },
    {
      label: 'Adventurer Finder',
      icon: faHatWizard,
      href: '/adventurers',
      classes: user ? 'mt-auto' : ''
    },
    {
      label: 'Shrines Finder',
      icon: faCrystalBall,
      href: '/shrines-finder'
    },
    {
      label: 'White Scroll',
      icon: faScrollOld,
      iconAfter: faArrowUpRightFromSquare,
      href: 'https://whitescroll.tavernsquad.io',
      target: '_blank'
    }
  ]

  return (
    <>
      {toolsOpen && (
        <ul className={`${className}`}>
          {toolsItems.map(item => {
            return (
              <NavListItem
                key={item.href}
                link={item}
                iconOnly={iconsOnly}
                updates={item.updates}
              />
            )
          })}
        </ul>
      )}

      {user && !toolsOpen && (
      <ul className={`${className}`}>
        {userItems.map(item => {
          return (
            <NavListItem
              key={item.href}
              link={item}
              iconOnly={iconsOnly}
              updates={item.updates}
            />
          )
        })}
      </ul>
      )}

      {!toolsOpen && (
        <ul className={`${className} ${user ? 'mt-auto' : ''}`}>
          {items.map(item => {
            return (
              <NavListItem
                key={item.href}
                link={item}
                iconOnly={iconsOnly}
              />
            )
          })}
        </ul>
      )}

    </>
  )
}
