import Link from 'next/link'
import { useRouter } from 'next/router'
import Button from '@atoms/Button'
import ButtonLink from '@atoms/ButtonLink'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faUserCircle } from '@fortawesome/pro-solid-svg-icons'
import { useSelector } from 'react-redux'
import Notifications from 'components/account/Notifications'
import WalletSelect from 'components/raids/WalletSelect'

export default function MobileHeader ({ onToggle, isTavern }) {
  const user = useSelector(store => store.main.auth.user)
  const router = useRouter()

  return (
    <div className={'lg:hidden h-[56px]'}>
      <header className={`bg-dark z-30 ${router.pathname === '/' ? '' : 'border-b border-primary fixed w-full'}`}>
        <div className="container relative flex items-center justify-between px-4 py-2 mx-auto z-1">
          <Link
            href="/"
            legacyBehavior
          >
            <a className="flex justify-center">
              <img
                src={'/ale-and-axes-logo.png'}
                alt="Tavern Squad logo"
                className="h-8"
              />
            </a>
          </Link>

          <div className="bg-purple-600 text-sm uppercase font-semibold leading-0 rounded-sm px-3 py-1 mr-auto ml-3 lg:hidden">
            Beta
          </div>

          <div className="flex items-center rounded bg-opacity-80">
            <div className="flex space-x-2">
              {user && (
                <>
                  <div className="p-2 align-middle rounded xs:block bg-tertiary-hover ring-1 ring-primary ring-inset">
                    <WalletSelect />
                  </div>
                  <div className="p-2 align-middle rounded xs:block bg-tertiary-hover ring-1 ring-primary ring-inset">
                    <Notifications />
                  </div>
                </>
              )}

              <ButtonLink
                className="p-2 align-middle rounded xs:block bg-tertiary-hover ring-1 ring-primary ring-inset"
                href="/tavern"
                bounce
              >
                <FontAwesomeIcon
                  icon={faUserCircle}
                  className="inline w-5 h-5 align-middle"
                />
                <span className="hidden">
                  {' '}
                  Account
                </span>
              </ButtonLink>
              <Button
                className="flex items-center align-middle rounded"
                onClick={onToggle}
              >
                <FontAwesomeIcon
                  icon={faBars}
                  className="w-8 h-8"
                />
              </Button>
            </div>
          </div>
        </div>
      </header>
    </div>
  )
}
