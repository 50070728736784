import { faPause, faPlay } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useOutsideClick from 'hooks/useOutsideClick'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Tooltip } from 'react-tippy'

const AudioPlayer = ({ route }) => {
  const user = useSelector(store => store.main.auth.user)
  const [audio] = useState(new Audio())
  const [playing, setPlaying] = useState(false)
  const [userInteracted, setUserInteracted] = useState(false)
  const [hasPaused, setHasPaused] = useState(false)
  const getTrack = (route) => `https://d1xrg19s0mtqrh.cloudfront.net/soundtracks/${route.startsWith('/raids/') ? 'tavern-squad-the-dungeon' : 'tavern-squad-tales-of-the-tavern'}.mp3`
  const [currentTrack, setCurrentTrack] = useState(getTrack(route))
  const handleOutsideClick = () => {
    if (userInteracted) {
      return
    }
    setUserInteracted(true)
  }
  const ref = useOutsideClick(handleOutsideClick)

  const loadTrack = trackToLoad => {
    audio.src = trackToLoad
    audio.load()
  }

  const changeTrack = (newTrack) => {
    if (audio.src !== newTrack) {
      const fadeOutInterval = setInterval(() => {
        if (audio.volume > 0.1) {
          audio.volume -= 0.1
        } else {
          clearInterval(fadeOutInterval)
          loadTrack(newTrack)
          if (playing) {
            audio.play()
          }
          audio.volume = 1
        }
      }, 100)
    }
  }

  useEffect(() => {
    audio.addEventListener('ended', () => {
      audio.currentTime = 0
      if (playing) {
        audio.play()
      }
    })

    return () => {
      audio.pause()
      audio.removeEventListener('ended')
    }
  }, [])

  useEffect(() => {
    const newTrack = getTrack(route)
    if (currentTrack !== newTrack) {
      changeTrack(newTrack)
      setCurrentTrack(newTrack)
    }
  }, [route])

  useEffect(() => {
    if (audio && window.innerWidth > 768 && userInteracted && !playing && !user.disable_autoplay && !hasPaused) {
      audio.src = getTrack(route)
      audio.load()
      audio?.play()
      setPlaying(true)
    }
  }, [userInteracted])

  const togglePlay = () => {
    if (playing) {
      audio.pause()
      setHasPaused(true)
    } else {
      loadTrack(getTrack(route))
      audio.play()
    }
    setPlaying(!playing)
  }

  return (
    <div ref={ref}>
      <Tooltip title="Play/pause soundtrack. You can enable/disable autoplay in settings.">
        <button
          type="button"
          onClick={togglePlay}
          className="w-[60px] h-[60px] p-5 rounded hover:bg-raids-dark hover:bg-opacity-40 flex items-center justify-center"
        >
          <FontAwesomeIcon
            icon={playing ? faPause : faPlay}
            className="w-6 h-6"
          />
        </button>
      </Tooltip>
    </div>
  )
}

export default AudioPlayer
